<script setup lang="ts">
const route = useRoute()
const cartStore = useCartStore()
const count = computed(() => cartStore.state.itemsCount)
</script>

<template>
  <ClientOnly>
    <NuxtLink
      :to="$checkoutUrl({ route, view: 'cart' })"
      data-test="cart-btn"
      prefetch
      :aria-label="$t('showCart') + ' ' + $t('products') + ' ' + count"
      @click="$event.stopImmediatePropagation()"
    >
      <IconCart />
    </NuxtLink>
</ClientOnly>
</template>
